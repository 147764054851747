// TODO: maybe move more funcionality to the Jobs model
// jT program tracks jobs up through submission
// Any submitted jobs that complete in the ifecycle will be referenced
// by the results service.
// TODO: simplify fetching by type; should be one fetch operation probably
// in which you can specify the type of job
// So this class will not know about all types
angular.module('sq.jobs.tracker.service', ['sq.jobs.model', 'sq.jobs.values'])
  .factory('jobTracker', jobTrackerFactory);

function jobTrackerFactory($log, $rootScope, $q, _, jobValues, Jobs) {
  let _all = {};
  let _completed = {};
  let _incomplete = {};
  let _failed = {};
  let _deleted = {};
  let _public = {};
  let _shared = {};

  // Better to do this than to use caching through cacheFactory...
  // that can return some very paradoxical results
  // Per recommendation of Jeff Cross (thanks!) https://github.com/angular/angular.js/issues/8307
  // handling this by checking whether we've ever had a resolution
  // Because we always intend to only fetch all data one time.
  let _initAllResolved = false;
  let _initializingAllPromise = null;

  let _initCompleteResolved = false;
  let _initCompletePromise = null;

  let _initSharedResolved = false;
  let _initSharedPromise = null;

  let _initIncompleteResolved = false;
  let _initIncompletePromise = null;

  let _initFailedResolved = false;
  let _initFailedPromise = null;

  let _initPublicResolved = false;
  let _initPublicPromise = null;

  const jT = {
    eventPrefix: 'sq.jobs.tracker.service:',
    jobs: {},
  };

  const _jobAddedEvent = 'sq.jobs.tracker.service:jobAdded';
  const _jobUpdatedEvent = 'sq.jobs.tracker.service:jobUpdated';
  const _jobErrorEvent = 'sq.jobs.tracker.service:jobUpdateError';

  Object.defineProperty(jT, 'jobAddedEvent', {
    get: () => _jobAddedEvent,
  });

  Object.defineProperty(jT, 'jobUpdatedEvent', {
    get: () => _jobUpdatedEvent,
  });

  Object.defineProperty(jT, 'jobErrorEvent', {
    get: () => _jobErrorEvent,
  });

  Object.defineProperties(jT.jobs, {
    all: {
      get: () => _all,
      enumerable: true,
    },
    incomplete: {
      get: () => _incomplete,
      enumerable: true,
    },
    completed: {
      get: () => _completed,
      enumerable: true,
    },
    failed: {
      get: () => _failed,
      enumerable: true,
    },
    deleted: {
      get: () => _deleted,
      enumerable: true,
    },
    public: {
      get: () => _public,
      enumerable: true,
    },
    shared: {
      get: () => _shared,
      enumerable: true,
    },
  });

  // TODO 2024-03-30 @akotlar figure out why Object.keys(jt.jobs.incomplete) doesn't work
  // despite being "enumerable"
  jT.getIncompleteCount = () => Object.keys(_incomplete).length;

  // May not be the best way to handle this.
  // Tried resolving all defer variables first, but this still resulted in issues
  // with routes not resolving, or resolving with no data, if clear() was called
  // too early.
  // In short, clear right now needs to be tied to Auth loggedIn/loggedOut state
  jT.clear = () => {
    _initAllResolved = false;
    _initializingAllPromise = null;

    _initCompleteResolved = false;
    _initCompletePromise = null;

    _initIncompleteResolved = false;
    _initIncompletePromise = null;

    _initFailedResolved = false;
    _initFailedPromise = null;

    _initPublicResolved = false;
    _initPublicPromise = null;

    _initSharedResolved = false;
    _initSharedPromise = null;

    _all = {}; _completed = {}; _incomplete = {}; _failed = {}; _deleted = {}; _public = {}; _shared = {};

    $log.debug('cleared called in jobs.tracker', jT);
  };

  const _broadcastUpdate = (job) => {
    $rootScope.$broadcast(_jobUpdatedEvent, job);
    $rootScope.$broadcast(`${_jobUpdatedEvent}:${job._id}`, job);
  };

  // Get all properties for one job
  // Async function, returns Jobs instance, or an error
  jT.getOneAsync = (id, force = false) => {
    const deferred = $q.defer();

    // TODO: would setting id = something mutate an object if it was passed
    // in place of id?
    const _id = _.isObject(id) ? id._id : id;

    if (jT.jobs.all[_id] && !force) {
      const deferred = $q.defer();
      deferred.resolve(jT.jobs.all[_id]);
      return deferred.promise;
    }

    Jobs.get({ _id }).$promise.then((job) => {
      const [err, addedJob] = jT.trackJobUpdate(job);

      if (err) {
        return deferred.reject(err);
      }

      deferred.resolve(addedJob);
    }).catch((err) => {
      deferred.reject(err);
    });

    return deferred.promise;
  };

  // TODO: it may be necessary to move away from buckets such as these
  jT.trackJobUpdate = (passedJob) => {
    if (!(passedJob && passedJob._id)) {
      var err = new Error('Invalid job', passedJob);
      $log.error(err);
      return [err, null];
    }

    let _id = passedJob._id;
    const hadJob = !!_all[_id];

    // Update the reference, for shallow watechers
    // We no longer do a shallow merge on the old data, too error prone
    // Just take the new job data as the correct state
    _all[_id] = new Jobs(_.assign({}, passedJob));

    let job = _all[_id];

    if (hadJob) {
      if (_incomplete[_id]) {
        _incomplete[_id] = job;
      }

      if (_failed[_id]) {
        _failed[_id] = job;
      }

      if (_completed[_id]) {
        _completed[_id] = job;
      }

      if (_deleted[_id]) {
        _deleted[_id] = job;
      }

      if (_public[_id]) {
        _public[_id] = job;
      }
    }

    if (job.isDeleted()) {
      _deleted[_id] = job;
      delete _completed[_id];
      delete _failed[_id];
      delete _incomplete[_id];
    } else if (job.isIncomplete()) {
      // Notifications may come out of order
      // if a "submitted" or "started" message really comes after a "failed"
      // we should ignore it
      if (_failed[_id]) {
        $log.warn("job had already failed, but now status is incomplete...", job);
      } else if (_completed[_id]) {
        $log.warn("job had already completed; but now status is incomplete...", job);
      } else {
        _incomplete[_id] = job;

        delete _completed[_id];
        delete _failed[_id];
      }
    } else if (job.isCompleted()) {
      _completed[_id] = job;

      //it's less clear what to do with the terminal states
      //these I've never seen happen
      if (_failed[_id]) {
        $log.warn("job had already failed, but now status is complete...", job);
      }

      delete _incomplete[_id];
      delete _failed[_id];

    } else if (job.isFailed()) {
      _failed[_id] = job;

      if (_completed[_id]) {
        $log.warn("job had already completed, but now status is failed...", job);
      }

      delete _incomplete[_id];
      delete _completed[_id];
    }

    if (_all[_id].isPublic()) {
      _public[_id] = job;
    } else if (_public[_id]) {
      delete _public[_id];
    }

    if (!hadJob) {
      $rootScope.$broadcast(_jobAddedEvent, job);
    }

    _broadcastUpdate(job);

    // Important that we return a reference to jobs.all, because
    // The other "buckets" may change, so if something wants to watch for
    // Any change to a job, they need this reference, and watch for 
    // reference changes
    return [null, job];
  };

  const _initializeCompleteJobsAsync = () => {
    if (_initCompleteResolved) {
      return $q.resolve(jT.jobs.completed);
    }

    if (_initCompletePromise) {
      return _initCompletePromise;
    }

    _initCompletePromise = Jobs.query().$promise.then((response) => {
      response.forEach((job) => {
        _all[job._id] = job;
        _completed[job._id] = _all[job._id];
      });

      _initCompleteResolved = true;
      return jT.jobs.completed;
    }).catch((rejection) => {
      _initCompleteResolved = false;
      $log.error(rejection);
    }).finally(() => {
      _initCompletePromise = null;
    });

    return _initCompletePromise;
  };

  const _initializeSharedJobsAsync = () => {
    if (_initSharedResolved) {
      return $q.resolve(jT.jobs.shared);
    }

    if (_initSharedPromise) {
      return _initSharedPromise;
    }

    _initSharedPromise = Jobs.shared().$promise.then((response) => {
      response.forEach((job) => {
        _all[job._id] = job;
        _shared[job._id] = _all[job._id];
      });

      _initSharedResolved = true;
      return jT.jobs.shared;
    }).catch((rejection) => {
      _initSharedResolved = false;
      $log.error(rejection);
    }).finally(() => {
      _initSharedPromise = null;
    });

    return _initSharedPromise;
  };

  const _initializePublicJobsAsync = () => {
    if (_initPublicResolved) {
      return $q.resolve(jT.jobs.public);
    }

    if (_initPublicPromise) {
      return _initPublicPromise;
    }

    _initPublicPromise = Jobs.public().$promise.then((response) => {
      response.forEach((job) => {
        _all[job._id] = job;
        _public[job._id] = _all[job._id];
      });

      _initPublicResolved = true;
      return jT.jobs.public;
    }).catch((rejection) => {
      _initPublicResolved = false;
      $log.error(rejection);
    }).finally(() => {
      _initPublicPromise = null;
    });

    return _initPublicPromise;
  };

  const _initializeIncompleteJobsAsync = () => {
    if (_initIncompleteResolved) {
      return $q.resolve(jT.jobs.incomplete);
    }

    if (_initIncompletePromise) {
      return _initIncompletePromise;
    }

    _initIncompletePromise = Jobs.incomplete().$promise.then((response) => {
      response.forEach((job) => {
        _all[job._id] = job;
        _incomplete[job._id] = _all[job._id];
      });

      _initIncompleteResolved = true;
      return jT.jobs.incomplete;
    }).catch((rejection) => {
      _initIncompleteResolved = false;
      $log.error(rejection);
    }).finally(() => {
      _initIncompletePromise = null;
    });

    return _initIncompletePromise;
  };

  const _initializeFailedJobsAsync = () => {
    if (_initFailedResolved) {
      return $q.resolve(jT.jobs.failed);
    }

    if (_initFailedPromise) {
      return _initFailedPromise;
    }

    _initFailedPromise = Jobs.failed().$promise.then((response) => {
      response.forEach((job) => {
        _all[job._id] = job;
        _failed[job._id] = _all[job._id];
      });

      _initFailedResolved = true;
      return jT.jobs.failed;
    }).catch((rejection) => {
      _initFailedResolved = false;
      $log.error(rejection);
    }).finally(() => {
      _initFailedPromise = null;
    });

    return _initFailedPromise;
  };

  jT.initializeAsync = () => {
    if (_initAllResolved) {
      return $q.resolve(jT.jobs);
    }

    if (_initializingAllPromise) {
      return _initializingAllPromise;
    }

    _initializingAllPromise = $q.all([
      _initializeIncompleteJobsAsync(),
      _initializeFailedJobsAsync(),
      _initializeSharedJobsAsync(),
      _initializePublicJobsAsync(),
      _initializeCompleteJobsAsync(),
    ]).then(() => {
      _initAllResolved = true;

      return jT.jobs;
    }, (err) => {
      _initAllResolved = false;
      $log.error(err);
    })
      .finally(() => {
        _initializingAllPromise = null;
      });

    return _initializingAllPromise;
  };

  jT.reconcile = (parentObject, patchObject) => {
    const jobToMutate = Object.assign({}, parentObject);

    // Helper function to deeply update an object
    function updateObject(target, updates) {
      Object.keys(updates).forEach(key => {
        if (typeof updates[key] === 'object' && updates[key] !== null && !Array.isArray(updates[key])) {
          if (!target[key]) {
            target[key] = {}; // Vivify the object if it doesn't exist
          }
          updateObject(target[key], updates[key]); // Recursive call for nested objects
        } else {
          target[key] = updates[key]; // Update the property
        }
      });
    }

    updateObject(jobToMutate, patchObject);

    jT.trackJobUpdate(jobToMutate);

    return parentObject;
  };

  // mutates job
  jT.patch = (job, data) => {
    return job.patch(data).then((updatedJob) => {
      const [err, trackedJob] = jT.trackJobUpdate(updatedJob);

      if (err) {
        $q.reject(err);
        return;
      }

      return trackedJob;
    });
  };

  return jT;
}